<template>
  <div>
    <div class="search-condition">
      <div class="search-flex">
        <div class="search-style">
          <span class="search-width">{{ $t("ctp.taskId") }}{{ $t("ctp.colon") }}</span>
          <el-input
            v-model="searchValue"
            :placeholder="$t('ctp.enterTaskID')"
            clearable
            prefix-icon="el-icon-search"
            class="search-input"
          />
        </div>
        <div class="search-style">
          <span class="search-width">{{ $t("ctp.creatTime") }}{{ $t("ctp.colon") }}</span>
          <el-date-picker
            v-model="timeScope.beginDate"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            :placeholder="$t('ctp.startDate')"
          />
          <span>-</span>
          <el-date-picker
            v-model="timeScope.endDate"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="timestamp"
            :placeholder="$t('ctp.endDate')"
          />
        </div>
        <div class="search-style">
          <span class="search-width">{{ $t("ctp.status") }}{{ $t("ctp.colon") }}</span>
          <el-select v-model="chStatus" :placeholder="$t('ctp.pleaseChoose')">
            <el-option
              v-for="item in searchStatus"
              :key="item.value"
              :label="item.name"
              :value="item"
            />
          </el-select>
        </div>
        <div class="search-style">
          <span class="search-width">{{ $t("ctp.site") }}{{ $t("ctp.colon") }}</span>
          <el-select v-model="siteData" @change="handleChangeSite">
            <el-option
              v-for="item in siteList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>
      <div class="seatchBtn">
        <el-button @click="reset">
          {{ $t("ctp.reset") }}
        </el-button>
        <el-button type="primary" @click="search">
          {{ $t("ctp.search") }}
        </el-button>
      </div>
    </div>
    <div class="table-outer-div">
      <el-table
        :data="data"
        style="width: 100%"
        id="sp-table"
        class="customer-table"
        border
        stripe
        @sort-change="initData"
        v-loading="tableLoading"
      >
        <el-table-column prop="taskId" :label="$t('ctp.taskID')" min-width="190" />
        <el-table-column
          prop="createTime"
          :label="$t('ctp.creatTime')"
          min-width="180"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.createTimeUTC">{{ Number(scope.row.createTimeLocal)| formatDate }}</span>
            <span v-else>{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('ctp.appNames')" min-width="170">
          <template slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light" popper-class="tooltip-title">
              <div slot="content">
                <div class="tip-content">
                  {{ scope.row.appName ? scope.row.appName : '--' }}
                </div>
              </div>
              <span class="content-hidden">{{ scope.row.appName ? scope.row.appName : '--' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column :label="$t('ctp.developerName')" min-width="170">
          <template slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light" popper-class="tooltip-title">
              <div slot="content">
                <div class="tip-content">
                  {{ scope.row.teamName ? scope.row.teamName : '--' }}
                </div>
              </div>
              <span class="content-hidden">{{ scope.row.teamName ? scope.row.teamName : '--' }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column :label="$t('ctp.developerInformation')" :min-width="language === 'ru' ? 270 : 140">
          <template slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light" popper-class="tooltip-title">
              <div slot="content">
                <div class="tip-content">
                  {{ scope.row.devEmail }}
                </div>
              </div>
              <span class="content-hidden">{{ scope.row.devEmail }}</span>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('ctp.cost')"
          :min-width="language === 'ru' ? 160 : 140"
          prop="cost"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span>{{ formatCost(scope.row.cost) }}
              &nbsp;{{ scope.row.currency ? scope.row.currency : '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('ctp.translatedDeadline')"
          width="180"
          prop="translatedDeadline"
          sortable="custom"
        >
          <template slot-scope="scope">
            <span class="content-hidden" v-if="scope.row.expectDeliveryTimeUTC">
              {{ Number(scope.row.expectDeliveryTimeLocal)|formatDate }}
            </span>
            <span class="content-hidden" v-else>
              {{ scope.row.expectDeliveryTime.length > 11 ?
                scope.row.expectDeliveryTime : scope.row.expectDeliveryTime + " 00:00:00" }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="$t('ctp.status')" min-width="140">
          <template slot-scope="scope">
            <span class="status-text">{{ status[scope.row.status] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('ctp.operation')"
          fixed="right"
          :min-width="language === 'cn' ? 130 : language === 'en' ? 190 : 220"
        >
          <template slot-scope="scope">
            <el-link type="primary" @click="showDetails(scope.row)" class="orderlist-process">
              <span class="btn-hover">{{ $t("ctp.see") }}</span>
            </el-link>
            <el-link
              type="primary"
              @click="openManuscriptManage(scope.row)"
              class="orderlist-process"
            >
              <span class="btn-hover">{{ $t("ctp.manuscriptManage") }}</span>
            </el-link>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-divider />
    <div class="orderlist-bottom" v-if="data.length > 0">
      <el-pagination
        background="true"
        @size-change="changePageSize"
        @current-change="onPage"
        :current-page="pagerindex"
        :page-sizes="[10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagertotal"
      />
    </div>

    <!--详情-->
    <sp-detail-dialog
      :detail-data="detailData"
      :dialog-visible="detailDialogVisiable"
      @changeVisiable="changeVisiable"
      @openUploadDialog="openUploadDialog"
      :site-data="siteData"
    />

    <!--稿件管理-->
    <manuscriptManage-dialog
      :detail-data="detailData"
      :manuscript-visible="manuscriptVisible"
      @cmanuscriptVisible="cmanuscriptVisible"
      :upload-file-size="uploadFileSize"
      :site-data="siteData"
    />
    <authorisation-dialog
      ref="authDialog"
      :siteval="siteData"
      @authSuccess="authSuccess"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import agc from '../../util/cloudstorage.js';
import moment from 'moment';
import { timestampToDate, getSiteName, getSiteId } from '../../util/common.js';
import { getConfigs, getPrivacyList } from '../../api/sp.js';
import { interactiveHaLog } from '../../util/interactiveHaLog.js';
import Cookie from 'js-cookie';
import spDetailDialog from './sp-detail-dialog.vue';
import manuscriptManageDialog from './manuscriptManage-dialog';
import authorisationDialog from './authorisation-dialog';
export default {
  name: 'Sorderlist',
  components: {
    'sp-detail-dialog': spDetailDialog,
    'manuscriptManage-dialog': manuscriptManageDialog,
    'authorisation-dialog': authorisationDialog
  },
  filters: {
    formatDate(value) {
      if (!value) return '';
      let timeTemp = timestampToDate(value);
      let lan=localStorage.getItem('agc_lang') || localStorage.getItem('agc_lang_site');
      if(lan==='ru'){
        timeTemp = moment(timeTemp).format('DD.MM.YYYY HH:mm:ss');
      }
      return timeTemp;
    }
  },
  data() {
    return {
      searchStatus: [
        { value: 'all', name: this.$t('ctp.all') },
        { value: 'pay', name: this.$t('ctp.noProcessed') },
        { value: 'translate', name: this.$t('ctp.processing') },
        { value: 'completed', name: this.$t('ctp.completed') },
        { value: 'overtime', name: this.$t('ctp.overtime') },
        { value: 'refresh', name: this.$t('ctp.refresh') },
        { value: 'end', name: this.$t('ctp.end') }
      ],
      status: {
        pay: this.$t('ctp.noProcessed'),
        translate: this.$t('ctp.processing'),
        overtime: this.$t('ctp.overtime'),
        completed: this.$t('ctp.completed'),
        refresh: this.$t('ctp.refresh'),
        appraise: this.$t('ctp.end'),
        end: this.$t('ctp.end')
      },
      siteList: [
        { id: 11, value: 'CN', name: this.$t('ctp.dataSite.CN') },
        { id: 12, value: 'DE', name: this.$t('ctp.dataSite.DE') },
        { id: 13, value: 'SG', name: this.$t('ctp.dataSite.SG') },
        { id: 15, value: 'RU', name: this.$t('ctp.dataSite.RU') }
      ],
      siteListData: [],
      siteData: '',
      oldSiteData: '',
      chStatus: {},

      timeScope: {
        endDate: moment().valueOf(),
        beginDate: ''
      },
      searchValue: '',
      costAscend: true,
      createTimeAscend: false,
      expectedTimeAscend: true,
      data: [],
      detailDialogVisiable: false,
      detailData: {},
      dialogVisible: false,
      pagertotal: 0,
      pagerindex: 1,
      pageSize: 10,
      supplier: {}, // 所有供应商的id-name键值对
      appraise: {
        // 评价
        spId: '',
        taskId: '', // 任务id
        delivery: 0,
        quality: 0,
        service: 0
      },
      uploadTmx: false,
      uploadTranslate: false,
      agcUid: '',
      agcSpId: '',
      langMap: {},
      replyFiles: [], // 译稿文件数组
      tmFiles: [], // TM文件数组
      languages: [], // 所有语言数组
      language:
        localStorage.getItem('agc_lang') ||
        localStorage.getItem('agc_lang_site'), // 系统语言
      pageflag: '',
      manuscriptVisible: false,
      uploadFileSize: 50,
      tableLoading: false,
      zoneIdData: ''
    };
  },
  mounted() {
    if (sessionStorage.getItem('changeSiteZoneId')) {
      sessionStorage.removeItem('changeSiteZoneId');
    }
    this.zoneIdData = sessionStorage.getItem('tszoneId');
    const zoneId = sessionStorage.getItem('tszoneId');
    this.chStatus = this.searchStatus[0];
    this.agcUid =
      Cookie.get('agc_uid') || sessionStorage.getItem('agc_uid');
    this.agcSpId = sessionStorage.getItem('agc_translation_spid');
    this.getLanguages();
    this.getConfigsData();
    // 获取默认站点
    if (sessionStorage.getItem('agc_team_countryCode')) {
      this.siteData = sessionStorage.getItem('agc_team_countryCode');
      this.oldSiteData = sessionStorage.getItem('agc_team_countryCode');
    } else {
      const siteValue = getSiteName(zoneId);
      this.siteData = siteValue;
      this.oldSiteData = siteValue;
    }
    // 获取授权信息
    if (this.agcSpId) {
      this.getPrivacyListData(this.agcSpId, zoneId);
    } else {
      this.$promptMsg(
        this.$t('ctp.error.systemAbnormal'),
        this.$t('ctp.tips')
      );
    }
  },
  beforeDestroy() {
    if (sessionStorage.getItem('changeSiteZoneId')) {
      sessionStorage.removeItem('changeSiteZoneId');
    }
  },
  methods: {
    // 切换站点
    handleChangeSite(val) {
      const valData = String(val);
      if (this.siteListData.includes(valData)) {
        this.siteData = val;
        this.oldSiteData = val;

        sessionStorage.setItem('changeSiteZoneId', getSiteId(val));
        agc.initConfig(getSiteId(val));
        Vue.prototype.$agc = agc;
        // 请求数据 加站点
        this.initData();
      } else {
        this.$refs.authDialog.handleOpenAuthorisation();
      }
    },
    authSuccess(val) {
      if (val) {
        this.oldSiteData = this.siteData;
      } else {
        this.siteData = this.oldSiteData;
      }
      this.getPrivacyListData(this.agcSpId);
    },
    // 获取该站点授权列表
    getPrivacyListData(val, zoneId) {
      if (val) {
        getPrivacyList({ spId: val }, zoneId).then(res => {
          if (res.ret.code === 0) {
            if (res.data.length === 0) {
              this.$refs.authDialog.handleOpenAuthorisation();
            } else {
              const siteIdList = [];
              res.data.forEach(el => {
                siteIdList.push(el.siteId);
              });
              this.siteListData = siteIdList;
              if (!siteIdList.includes(this.siteData)) {
                this.$refs.authDialog.handleOpenAuthorisation();
              } else {
                this.initData();
              }
            }
          }
        });
      }
    },
    getConfigsData() {
      getConfigs('SP', 'TranslationRspFile. allowFileTotalSize')
        .then(res => {
          if (res.ret.code === 0) {
            this.uploadFileSize = res.data[0].ruleValue;
          } else {
            this.$promptMsg(
              `${this.$t('ctp.tips.payError.abnormal')}(${
                res.errorCode ? res.errorCode : res.ret.code
              })`,
              this.$t('ctp.tips')
            );
          }
        })
        .catch(err => {
          this.$promptMsg(
            this.$t('ctp.error.systemAbnormal'),
            this.$t('ctp.tips')
          );
          return err;
        });
    },
    formatCost(cost) {
      let costTemp = cost ? Number(cost).toFixed(2) : '';
      if (this.language === 'ru') {
        costTemp = costTemp.replace(/,/g, ' ').replace(/\./g, ',');
      }
      return costTemp;
    },
    // 加载订单列表
    initData(sort) {
      this.tableLoading = true;
      this.pageflag = sort;
      if (Object.keys(this.langMap).length < 2) {
        this.getLanguages();
      }
      const body = {
        start:
          ((this.pagerindex < 1 ? 1 : this.pagerindex) - 1) * this.pageSize,
        length: this.pageSize
      };
      if (this.chStatus.value !== 'all') {
        body.status = [this.chStatus.value];
      }
      if (this.searchValue && this.searchValue.length > 0) {
        body.search = this.searchValue;
      }
      if (this.timeScope.beginDate) {
        body.begin = this.timeScope.beginDate;
      }

      if (this.timeScope.endDate) {
        body.end = this.timeScope.endDate;
      }

      if (sort) {
        if (sort.prop === 'createTime') {
          body.sort = 1;
          this.createTimeAscend = sort.order === 'ascending';
          body.ascend = this.createTimeAscend;
        } else if (sort.prop === 'cost') {
          body.sort = 2;
          this.costAscend = sort.order === 'ascending';
          body.ascend = this.costAscend;
        } else if (sort.prop === 'translatedDeadline') {
          body.sort = 3;
          this.expectedTimeAscend = sort.order === 'ascending';
          body.ascend = this.expectedTimeAscend;
        }
      } else {
        body.sort = 1;
        body.ascend = this.createTimeAscend;
      }
      body.spId = this.agcSpId;
      const zoneIdVal = Number(getSiteId(this.siteData));
      AGC.callServiceAPI(
        'translation/v1/sp/taskList',
        { body, header: {} },
        'POST',
        zoneIdVal,
        3000,
        res => {
          this.tableLoading = false;
          if (res.ret.code === 0) {
            this.data = res.data;
            this.data.forEach(el => {
              el.sourceLanguage = el.sourceLanguage
                ? el.sourceLanguage.split('-')[0]
                : '';
              const tarLang = el.targetLanguage;
              const arr = [];
              tarLang.split(',').forEach(t => {
                arr.push(t.split('-')[0]);
              });
              el.targetLanguage = arr.join(this.$t('ctp.comma'));
              el.createTimeLocal = el.createTimeUTC;
              el.expectDeliveryTimeLocal = el.expectDeliveryTimeUTC;
              res.data.forEach(row => {
                const tarLanguageArr = [];
                this.languages.forEach(item => {
                  if (item.languageId === row.sourceLanguageId) {
                    row.sourceLanguage =
                      this.language === 'cn'
                        ? item.languageNameCh
                        : this.language === 'en'
                          ? item.languageNameEn
                          : item.languageNameRu;
                  }
                  row.targetLanguageId.split(',').forEach(lang => {
                    if (lang === item.languageId) {
                      tarLanguageArr.push(
                        this.language === 'cn'
                          ? item.languageNameCh
                          : this.language === 'en'
                            ? item.languageNameEn
                            : item.languageNameRu
                      );
                    }
                  });
                  row.targetLanguage = tarLanguageArr.join(',');
                });
                this.data = res.data;
              });
            });
            this.pagertotal = res.total ? Number.parseInt(res.total) : 0;
          } else {
            this.data = [];
            this.pagertotal = 0;
          }
        },
        err => {
          this.tableLoading = false;
          this.data = [];
          this.pagertotal = 0;
          if (err.code === 403 || err.status === 403) {
            this.$alertMsg(
              this.$t('ctp.tips.noOperationRights'),
              this.$t('ctp.warning')
            );
          } else {
            this.$alertMsg(
              this.$t('ctp.error.searchTaskFail'),
              this.$t('ctp.warning')
            );
          }
        }
      );
    },
    search() {
      interactiveHaLog({
        title: '翻译服务SP--查询',
        tagType: 'TranslationService',
        translationServiceId: ''
      });
      this.pagerindex = 1;
      this.initData(this.pageflag);
    },
    getLanguages() {
      const zoneIdVal = Number(getSiteId(this.siteData));
      const url = 'translation/v1/cp/getLanguages';
      AGC.callServiceAPI(
        url,
        { header: {}, body: {} },
        'POST',
        zoneIdVal,
        3000,
        res => {
          if (res.ret.code === 0) {
            this.languages = res.data;
            this.langMap = {};
            res.data.forEach(e => {
              this.langMap[e.languageId] = e.languageNameCh;
            });
          }
        },
      );
    },

    openManuscriptManage(item) {
      this.detailData = item;
      this.detailData.agcSpId = this.agcSpId;
      this.detailData.agcUid = this.agcUid;
      const zoneId = getSiteId(this.siteData);
      AGC.callServiceAPI(
        'translation/v1/sp/taskDetails',
        {
          body: {
            taskId: item.taskId,
            uid: this.agcUid,
            spId: this.agcSpId
          },
          header: {}
        },
        'POST',
        zoneId,
        3000,
        res => {
          if (res.ret.code === 0) {
            res.taskReplyList.forEach(el => {
              el.createTimeLocal = el.createTimeUTC;
            });
            this.detailData.taskReplyList = res.taskReplyList;
            this.manuscriptVisible = true;
          }
        },
        err => {
          this.$alertMsg(
            this.$t('ctp.error.searchFail'),
            this.$t('ctp.warning')
          );
          return err;
        }
      );
    },
    showDetails(data) {
      interactiveHaLog({
        title: '翻译服务SP--查看',
        tagType: 'TranslationService',
        translationServiceId: data.taskId
      });
      this.detailData.currency = data.currency ? data.currency : '--';
      const zoneId = getSiteId(this.siteData);
      AGC.callServiceAPI(
        'translation/v1/sp/taskDetails',
        {
          body: {
            taskId: data.taskId,
            uid: this.agcUid,
            spId: this.agcSpId
          },
          header: {}
        },
        'POST',
        zoneId,
        3000,
        res => {
          if (res.ret.code === 0) {
            this.detailData = data;
            this.detailData.taskReplyList = res.taskReplyList;
            this.detailData.taskQualityList = res.taskQualityList;
            let arr = [];
            let arr2 = [];
            const TARGET_LANGUAGE = data.targetLanguageId.split(',');
            res.targetLangList.forEach((el1) => {
              const index = TARGET_LANGUAGE.indexOf(el1.targetLanguageId);
              if (index !== -1) {
                TARGET_LANGUAGE.splice(index, 1);
              }
            });

            TARGET_LANGUAGE.forEach((el2) => {
              this.languages.forEach((val2) => {
                if (el2 === val2.languageId) {
                  if (this.language === 'cn') {
                    arr2.push(val2.languageNameCh);
                  } else if (this.language === 'en') {
                    arr2.push(val2.languageNameEn);
                  } else if (this.language === 'ru') {
                    arr2.push(val2.languageNameRu);
                  } else {
                    arr2.push(val2.languageNameEn);
                  }
                }
              });
            });
            arr2 = arr2.sort((a, b) =>
              a.localeCompare(b, 'zh-Hans-CN', { sensitivity: 'accent' })
            );
            this.detailData.notSupportLangName = arr2.join(
              this.$t('ctp.comma')
            );
            this.detailData.notSupportLangLength = arr2 ? arr2.length : 0;

            res.targetLangList.forEach((el) => {
              this.languages.forEach((val) => {
                if (val.languageId === el.targetLanguageId) {
                  if (this.language === 'cn') {
                    el.languageName = val.languageNameCh;
                  } else if (this.language === 'en') {
                    el.languageName = val.languageNameEn;
                  } else if (this.language === 'ru') {
                    el.languageName = val.languageNameRu;
                  } else {
                    el.languageName = val.languageNameEn;
                  }
                  arr.push(el);
                }
              });
            });
            arr = arr.sort((a, b) =>
              a.languageName.localeCompare(b.languageName, 'zh-Hans-CN', {
                sensitivity: 'accent'
              })
            );
            this.detailData.targetLangList = arr;
            this.data.forEach(e => {
              if (e.taskId === this.detailData.taskId) {
                e.taskReplyList = this.detailData.taskReplyList;
                e.taskQualityList = this.detailData.taskQualityList;
                e.targetLangList = this.detailData.targetLangList;
              }
            });
            this.detailDialogVisiable = true;
          }
        },
        err => {
          this.$alertMsg(
            this.$t('ctp.error.searchFail'),
            this.$t('ctp.warning')
          );
          return err;
        }
      );
    },
    reset() {
      this.pageflag = '';
      this.searchValue = '';
      this.$set(this.timeScope, 'endDate', moment().valueOf());
      this.$set(this.timeScope, 'beginDate', '');
      this.chStatus = this.searchStatus[0];
    },
    cmanuscriptVisible(dVisible, refresh) {
      this.manuscriptVisible = dVisible;
      if (refresh === true) {
        this.initData();
      }
    },
    changeVisiable(dialogVisible, refeshStatus) {
      this.detailDialogVisiable = dialogVisible;
      if (refeshStatus === true) {
        this.initData();
      }
    },
    openUploadDialog(refresh) {
      if (refresh === true) {
        this.initData();
      }
      this.uploadVisible = true;
    },
    closeDialog() {
      this.replyFiles = [];
      this.tmFiles = [];
      this.uploadVisible = false;
    },
    // 分页
    onPage(pageIndex) {
      this.pagerindex = pageIndex;
      this.initData(this.pageflag);
    },
    changePageSize(newPageSize) {
      this.pageSize = newPageSize;
      this.initData(this.pageflag);
    }
  }
};
</script>

<style scoped>
.btn-en {
  width: 168px !important;
  padding-left: 20px !important;
  padding-right: 20px !important ;
}
.btn-ru {
  width: 238px !important;
  padding-left: 20px !important;
  padding-right: 20px !important ;
}
.btn-cn {
  width: 118px !important;
  padding-left: 20px !important;
  padding-right: 20px !important ;
}
.btn-hover:hover {
  border-bottom: 1px solid;
}
.status-span {
  margin-left: 20px;
  margin-right: 8px;
}
.orderlist-bottom {
  text-align: right;
  padding-right: 28px;
}
.table {
  width: 100%;
  margin-bottom: 0;
  border-collapse: collapse;
}
tr.tr-main td {
  line-height: 32px;
  padding: 8px 0 8px 17px;
  color: #666;
  border: 0;
}

tr.tr-main:nth-child(2n) {
  background-color: #f5f6f7;
}

.orderlist-tr-nodata {
  text-align: center;
  color: #666;
  line-height: 48px;
}
.table-th td {
  border-top: 1px solid #e0e0e0;
  padding: 9px 0 9px 8px;
}
.supplier-mar-right {
  margin-right: 4px;
  cursor: pointer;
  font-size: 18px;
}
.appraise-top ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.appraise-top {
  padding-left: 12px;
}
.supplier-lang-table {
  width: 100%;
}
.supplier-lang-table tr th,
td {
  padding: 4px 0 4px 12px;
}
.qc-top {
  list-style: none;
  padding: 0;
}
.qc-top li:not(:last-child) {
  float: left;
  margin-right: 20px;
}
.detail-dialog-div {
  padding: 10px 0;
}
.table-th {
  border-bottom: 0;
  border-top: 1px solid #e2e2e2;
}
.table-th > td::before {
  content: "";
  height: 8px;
  border-right: 1px solid #e2e2e2;
  margin-right: 8px;
}
.appraise-text {
  height: 72px;
  margin-top: 12px;
  padding-left: 8px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
}
.upload-btn {
  padding: 4px 12px;
  font-size: 14px;
  background-color: #fff;
  color: #3d3d3d;
  outline: none;
  border: 1px solid #999;
  border-radius: 8px;
}
.no-data-table {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #f5f6f7;
}
.clear-both {
  clear: both;
}
.table-outer-div {
  padding: 0 24px;
  margin-top: 12px;
}
.search-input {
  width: 195px !important;
}
.sort-caret {
  width: 0;
  height: 0;
  border: 5px solid transparent;
  position: absolute;
  left: 7px;
}
.sort-caret.ascending {
  border-bottom-color: #c0c4cc;
  top: -13px;
}
.sort-caret.descending {
  border-top-color: #c0c4cc;
  bottom: -9px;
}
.ascending .sort-caret.ascending {
  border-bottom-color: #007dff;
}
.descending .sort-caret.descending {
  border-top-color: #007dff;
}
.caret-wrapper {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  vertical-align: middle;
  cursor: pointer;
  overflow: initial;
  position: relative;
}
.seatchBtn {
  min-width: 220px;
  margin-bottom: 20px;
}
.search-top {
  padding-left: 24px;
}
.upload-translate-div /deep/ button {
  padding: 10px 30px;
}
.detail-dialog-div /deep/ .el-upload-list__item-name {
  cursor: pointer;
}
.tips {
  color: rgb(175, 170, 170);
  font-size: 13px;
  padding: 10px 0;
  word-break: break-word;
}
div /deep/.tooltip-title {
  overflow-y: auto !important;
  max-width: 30%;
  max-height: 20%;
}
div /deep/ .tip-title {
  overflow-y: auto !important;
  max-width: 25%;
  max-height: 15%;
}
:focus{
	outline: none;
}
/* 去掉表格单元格边框 */
div /deep/ .customer-table th {
  border: none !important;
}
.customer-table td,
.customer-table th.is-leaf {
  border: none !important;
}
/* 表格最外边框 */
div /deep/.el-table--border,
div /deep/.el-table--group {
  border: none !important;
}
/* 头部边框 */
div /deep/.customer-table thead tr th.is-leaf {
  border-top: 1px solid #ebeef5 !important;
  border-bottom: 1px solid #ebeef5 !important;
  border-right: none;
}
div /deep/.customer-table thead tr th:nth-last-of-type(2) {
  border-right: 1px solid #ebeef5;
}
/* 表格最外层边框-底部边框 */
div /deep/.el-table--border::after,
div /deep/.el-table--group::after {
  width: 0 !important;
}
div /deep/ #sp-table th:not(:nth-last-of-type(2)) > .cell:before {
  content: "";
  border-right: 1px solid #e0e0e0;
  position: absolute;
  right: 10px;
  top: 5px;
  height: 12px;
}
div /deep/ .content-hidden {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
div /deep/.el-table--striped td,
div /deep/.el-table--striped th.is-leaf {
  border-right-color: transparent;
}
.search-condition {
  display: flex;
  flex-wrap: wrap;
}
.search-flex {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  min-width: 650px;
}
.search-style {
  margin-right: 20px;
  margin-bottom: 20px;
}
.search-width {
  width: 130px;
  display: inline-block;
  text-align: right;
  margin-right: 8px;
}
.orderlist-process {
  margin-left: 4px;
}
.status-text {
  word-break: break-word;
}
</style>

