<template>
  <div v-if="dialogVisible" class="sp-detail-dia">
    <agc-dialog
      custom-class="dialog"
      width="80%"
      @close="close"
      :visible.sync="dialogVisible"
      :title="$t('ctp.queryTask')"
    >
      <div class="item-title">
        {{ $t("ctp.taskDetail") }}
      </div>
      <div class="bodybox">
        <ul class="normal-ul">
          <li>
            <span>{{ $t("ctp.appNames") }}{{ $t("ctp.colon") }}</span>
            <span>{{ detailData.appName ? detailData.appName : "--" }}</span>
          </li>
          <li>
            <span>{{ $t("ctp.developerName") }}{{ $t("ctp.colon") }}</span>
            <span>{{ detailData.teamName ? detailData.teamName : "--" }}</span>
          </li>
          <li>
            <span>{{ $t("ctp.expectedTime") }}{{ $t("ctp.colon") }}</span>
            <span v-if="detailData.expectDeliveryTimeUTC">
              {{ Number(detailData.expectDeliveryTimeLocal) | formatDate }}
            </span>
            <span v-else>
              {{
                detailData.expectDeliveryTime.length > 11
                  ? detailData.expectDeliveryTime
                  : detailData.expectDeliveryTime + " 00:00:00"
              }}
            </span>
          </li>
          <li>
            <span>{{ $t("ctp.taskStatus") }}{{ $t("ctp.colon") }}</span>
            <span>{{ curStatus[detailData.status] }}</span>
          </li>
          <li>
            <span>{{ $t("ctp.developerInformation") }}{{ $t("ctp.colon") }}</span>
            <span>{{ detailData.devEmail }}</span>
          </li>
          <li>
            <span>{{ $t("ctp.orderRemarks") }}{{ $t("ctp.colon") }}</span>
            <span>
              {{
                detailData.remarksForSp ? detailData.remarksForSp : "--"
              }}
            </span>
          </li>
        </ul>
        <table class="dialog-table">
          <tbody>
            <tr>
              <th>{{ $t("ctp.translationContent") }}</th>
              <th>{{ $t("ctp.originLanguage") }}</th>
              <th>{{ $t("ctp.targetLanguage") }}</th>
              <th>
                {{ $t("ctp.totalWords") }}
                <el-tooltip
                  class="item"
                  effect="light"
                  placement="top-start"
                  popper-class="tooltip-title"
                >
                  <div slot="content">
                    {{ $t("ctp.tips.totalWords") }}
                  </div>
                  <agc-icon name="question" color="#c5c5c5" size="18" />
                </el-tooltip>
              </th>
              <th>{{ $t("ctp.singWordUnitPrice", {currency: detailData.currency}) }}</th>
              <th>{{ $t("ctp.singleLangTotal", {currency: detailData.currency}) }}</th>
            </tr>
            <tr v-for="(lang, index) in detailData.targetLangList" :key="index" class="tr-body">
              <td :rowspan="detailData.targetLangList.length" v-if="index === 0">
                <div
                  v-if="
                    detailData.application && detailData.application === 'true'
                  "
                >
                  <el-tooltip effect="light" placement="bottom-start" popper-class="tooltip-title">
                    <div slot="content">
                      {{
                        detailData.applicationName +
                          "-" +
                          detailData.appIntroduceInshort +
                          "-" +
                          detailData.appIntroduce +
                          (detailData.appFeatures
                            ? "-" + detailData.appFeatures
                            : "")
                      }}
                    </div>
                    <span>
                      {{
                        (
                          detailData.applicationName +
                          "-" +
                          detailData.appIntroduce +
                          "-" +
                          detailData.appIntroduceInshort +
                          (detailData.appFeatures
                            ? "-" + detailData.appFeatures
                            : "")
                        ).length > 15
                          ? (
                            detailData.applicationName +
                            "-" +
                            detailData.appIntroduce +
                            "-" +
                            detailData.appIntroduceInshort +
                            (detailData.appFeatures
                              ? "-" + detailData.appFeatures
                              : "")
                          ).substring(0, 15) + "..."
                          : detailData.applicationName +
                            "-" +
                            detailData.appIntroduce +
                            "-" +
                            detailData.appIntroduceInshort +
                            (detailData.appFeatures
                              ? "-" + detailData.appFeatures
                              : "")
                      }}
                    </span>
                  </el-tooltip>
                </div>
                <div
                  v-if="detailData.sourceFileName && detailData.sourceFileName !== ''"
                  :title="detailData.sourceFileName"
                >
                  {{ detailData.sourceFileName
                    ? detailData.sourceFileName.length > 15
                      ? detailData.sourceFileName.substring(0, 15) + "..."
                      : detailData.sourceFileName
                    : "--" }}
                </div>
              </td>
              <td
                :rowspan="detailData.targetLangList.length"
                v-if="index === 0"
              >
                {{ detailData.sourceLanguage }}
              </td>
              <td>{{ lang.languageName }}</td>
              <td>{{ lang.totalWords ? Math.ceil(Number(lang.totalWords)) : "--" }}</td>
              <td>
                <span>{{ Number(lang.preCost) }}</span>
              </td>
              <td>
                <span>
                  {{
                    lang.originalCost
                      ? Number(lang.originalCost).toFixed(2)
                      : "--"
                  }}
                </span>
              </td>
            </tr>
            <tr class="noTargetLan" v-if="detailData.notSupportLangLength">
              <td :colspan="6" class="noTarget">
                <span>
                  <span>{{ $t("ctp.tips.notSPSupportedLang", {num: detailData.notSupportLangLength}) }}</span>
                  <br>
                  <span>{{ detailData.notSupportLangName }}</span>
                </span>
              </td>
            </tr>
            <tr class="priceTable" v-if="detailData.status !== 'init'">
              <td :colspan="6">
                <div class="float-rt">
                  <div class="orderPrice">
                    {{
                      detailData.originalCost
                        ? Number(detailData.originalCost).toFixed(2)
                        : "--"
                    }}
                  </div>
                  <div
                    class="discountedPrice"
                    v-if="
                      detailData.discount &&
                        Number(detailData.discount) !== -1
                    "
                  >
                    -{{ Number(detailData.discount).toFixed(2) }}
                  </div>
                  <div
                    class="discountedPrice"
                    v-else
                  >
                    -{{ (Number(detailData.originalCost) - Number(detailData.cost)).toFixed(2) }}
                  </div>
                  <div class="reward">
                    -{{
                      detailData.reward && Number(detailData.discount) !== -1
                        ? Number(detailData.reward).toFixed(2)
                        : "0.00"
                    }}
                  </div>
                  <div class="totalPrice">
                    {{
                      detailData.cost
                        ? Number(detailData.cost).toFixed(2)
                        : "--"
                    }}
                  </div>
                </div>
                <div class="float-rt">
                  <div
                    class="priceLabel"
                  >
                    {{ $t("ctp.totalPrice", {currency: detailData.currency}) }}{{ $t("ctp.colon") }}
                  </div>
                  <div class="priceLabel">
                    {{ $t("ctp.preference", {currency: detailData.currency}) }}
                    <el-tooltip class="item" effect="light" placement="bottom-start">
                      <div slot="content">
                        {{ $t("ctp.tips.preference") }}
                      </div>
                      <agc-icon name="question" color="#c5c5c5" size="18" />
                    </el-tooltip>
                    {{ $t("ctp.colon") }}
                  </div>
                  <div class="priceLabel">
                    {{ $t("ctp.vouchers", {currency: detailData.currency}) }}
                    <el-tooltip class="item" effect="light" placement="bottom-end">
                      <div slot="content">
                        {{ $t("ctp.vouchersTips") }}
                      </div>
                      <agc-icon name="question" color="#c5c5c5" size="18" />
                    </el-tooltip>
                    {{ $t("ctp.colon") }}
                  </div>
                  <div
                    class="priceLabel"
                  >
                    {{ $t("ctp.actualPrice", {currency: detailData.currency}) }}{{ $t("ctp.colon") }}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="upload-download-operation">
          <el-button @click="downloadOrigin" v-if="detailData.sourceFilePath">
            {{
              $t("ctp.downloadSourceFile")
            }}
          </el-button>
        </div>
      </div>

      <div class="middle-line" />
      <span slot="footer" class="footer-button">
        <el-button @click="close" id="close-search-modal">
          {{
            $t("ctp.close")
          }}
        </el-button>
      </span>
    </agc-dialog>

    <!--上传qc文件弹窗-->
    <agc-dialog :visible.sync="qcDialogVisible" width="50%" :title="$t('ctp.initiatingConfirm')">
      <div>
        <div class="qc-dialog-top">
          <span style="font-size:16px;">
            {{
              $t("ctp.problemDescription")
            }}
          </span>
          <span style="display:inline-block;width:64%;" />
          <span>{{ $t("ctp.sources") }}</span>
          <label class="radio">
            <input type="radio" name="qc_from" value="user" v-model="QCfrom">
            <span>{{ $t("ctp.user") }}</span>
          </label>
          <label class="radio">
            <input type="radio" name="qc_from" v-model="QCfrom" value="huawei">
            <span>{{ $t("ctp.huawei") }}</span>
          </label>
        </div>
        <div>
          <label class="ave-tip-input" style="width:100%;">
            <textarea
              type="text"
              style="height:72px;"
              v-model="remarks"
              :placeholder="remarks.length + '/300'"
            />
          </label>
        </div>
        <div>
          <input type="file" id="qcfile" ref="qcfile" class="display-none" @change="changeFile">
          <span>{{ $t("ctp.attachment") }}{{ $t("ctp.colon") }}</span>
          <span class="af-upload" @click="chooseTM('qcfile')" />
          <span>{{ qcUploadPath }}</span>
        </div>
        <div class="qc-btn-group">
          <el-button @click="qcDialogVisible = false">
            {{
              $t("ctp.cancel")
            }}
          </el-button>
          <el-button type="primary" @click="addOrderQulity">
            {{
              $t("ctp.ok")
            }}
          </el-button>
        </div>
      </div>
    </agc-dialog>
  </div>
</template>

<script>
import { timestampToDate, getSiteId } from '../../util/common.js';
import { changeBucket } from '../../util/changeBucket.js';
export default {
  name: 'SpDetailDialog',
  filters: {
    formatDate(value) {
      if (!value) return '';
      return timestampToDate(value);
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }, // 显示隐藏
    detailData: {
      type: Object,
      default: () => {}
    }, // 数据
    siteData: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      qcDialogVisible: false,
      QCfrom: 'user',
      remarks: '', // 质量确认问题弹框
      qcUploadPath: '', // qc说明文件路径
      refreshStatus: false, // 刷新状态
      curStatus: {
        pay: this.$t('ctp.noProcessed'),
        translate: this.$t('ctp.processing'),
        overtime: this.$t('ctp.overtime'),
        completed: this.$t('ctp.completed'),
        refresh: this.$t('ctp.refresh'),
        appraise: this.$t('ctp.end'),
        end: this.$t('ctp.end')
      },
      zoneIdData: ''
    };
  },
  methods: {
    close() {
      this.dialogVisible = false;
      this.$emit('changeVisiable', false, this.refreshStatus);
    },
    openDialog() {
      this.close();
      this.$emit('openUploadDialog', this.refreshStatus);
    },
    // 用户
    changeFile($event) {
      const suffix = $event.target.value.split('.').pop();
      if (suffix !== 'zip' && suffix !== 'rar') {
        this.$alertMsg('please choose zip/rar file!');
        this.qcUploadPath = '';
        return;
      }
      this.qcUploadPath = $event.target.value;
    },
    // 弹出选择文件窗口
    chooseTM(fid) {
      document.getElementById(fid).click();
    },
    showtoasts(type, message) {
      this.$notify({
        title: type,
        message,
        type
      });
    },
    downloadOrigin() {
      this.refreshStatus = true;
      if (this.detailData.sourceFilePath) {
        const storage = this.$agc.storage();
        const storageRef = storage.storageReference();
        const { sourceFilePath } = this.detailData;
        const childPath =
          sourceFilePath.indexOf(':') > -1
            ? sourceFilePath.substring(sourceFilePath.indexOf(':') + 1)
            : sourceFilePath;
        const child = storageRef.child(childPath);
        child.location.bucket = changeBucket(this.siteData);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
          AGC.callServiceAPI(
            'translation/v1/sp/downloadOrigin',
            {
              body: {
                spId: this.detailData.agc_spId,
                taskId: this.detailData.taskId
              }
            },
            'POST',
            getSiteId(this.siteData),
            3000,
            res => {
              if (this.detailData.status === 'pay') {
                this.refreshStatus = true;
                this.$set(this.detailData, 'status', 'translate');
                return res;
              }
            }
          );
        });
      } else {
        this.$alertMsg(
          this.$t('ctp.tips.noDownloadFile'),
          this.$t('ctp.warning')
        );
      }
    },
    downloadTranslated(index) {
      if (
        this.detailData.taskReplyList &&
        this.detailData.taskReplyList[index]
      ) {
        const taskReply0 = this.detailData.taskReplyList[index];
        const replyPath = taskReply0.filePath;
        const childPath =
          replyPath.indexOf(':') > -1
            ? replyPath.substring(replyPath.indexOf(':') + 1)
            : replyPath;
        const storage = this.$agc.storage();
        const child = storage.storageReference().child(childPath);
        child.location.bucket = changeBucket(this.siteData);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
        });
      } else {
        this.$alertMsg(this.$t('ctp.tips.noTranslated'), this.$t('ctp.tips'));
      }
    },
    addOrderQulity() {
      if (this.remarks.length < 2) {
        this.$alertMsg(this.$t('ctp.writeDescription'));
        return;
      }

      const data = new FormData();
      data.append('file', this.$refs.qcfile.files[0]);
      data.append('orderId', this.detailData.orderId);
      data.append('remarks', this.remarks);
      data.append('questionFrom', this.QCfrom);
    }
  }
};
</script>

<style scoped>
div /deep/ .el-dialog__footer {
  padding: 0px 20px 18px;
  height: 71px;
}
.tooltip-title {
  width: 400px;
  word-wrap: break-word;
}
.dialog-empty {
  padding: 4px 12px;
  font-size: 14px;
  background-color: #fff;
  color: #3d3d3d;
  outline: none;
  border: 1px solid #999;
  border-radius: 8px;
}
.bodybox {
  margin: 0 80px;
}
.dialog-up-step {
  padding: 4px 24px;
  font-size: 14px;
  background-color: #169bd5;
  color: #fff;
  outline: none;
  border: 0;
  border-radius: 4px;
}
.dialog table.dialog-table tr th,
td {
  padding: 12px 0;
  border: 1px solid #e2e2e2;
  text-align: center;
}
.dialog-table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  margin: 9px 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
ul li {
  padding: 9px 0;
}
th {
  font-weight: 900;
  color: #191919;
}
.normal-ul li span:first-child {
  display: inline-block;
  width: 350px;
  font-size: 14px;
  text-align: right;
  margin-right: 8px;
}
.middle-line {
  clear: both;
  margin-bottom: 18px;
  border-top: 1px solid #e2e2e2;
}
.item-title {
  font-size: 20px;
  color: #191919;
  margin-bottom: 9px;
}
.tr-body td {
  color: #666;
}
.no-completed-trans {
  display: inline-block;
  padding-left: 15%;
  font-size: 14px;
}
.fixed-width {
  display: inline-block;
  width: 35%;
}
.display-none {
  display: none;
}
.qc-btn-group {
  text-align: right;
  padding-top: 12px;
}
.reply-main-ul {
  padding-bottom: 9px;
  margin: 0 80px;
}
.expect-delivery-div {
  padding: 18px 0 20px 0;
}
.upload-download-operation {
  float: right;
  padding: 9px 0 18px;
}
.task-detail-info {
  float: left;
  width: 60%;
  padding: 4px 0 20px 0;
}
div /deep/ .el-dialog__body {
  padding: 18px 20px 0;
  max-height: 400px !important;
  min-height: 100px;
  overflow-y: auto;
}
.task-detail-row3 {
  padding: 20px 0 32px 0;
}
.footer-button {
  display: inline-block;
  position: relative;
  height: 35px;
  margin-top: 18px;
}
.float-rt {
  float: right;
  line-height: 28px;
  text-align: right;
  padding-right: 10px;
}
.priceTable {
  background-color: #f7f7f7;
  height: 117px;
}
.totalPrice {
  color: #fa2a2d;
  font-size: 20px;
}
.noTargetLan {
  height: 52px;
  color: #666666;
  text-align: left !important;
  word-break: break-all;
}
.noTargetLan .noTarget {
  padding-left: 10px;
  text-align: left !important;
}
</style>

<style>
#close-search-modal {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
