import { DEFAULT_BUCKET_ADDRESS, TESTDEFAULT_BUCKET_ADDRESS, STTESTDEFAULT_BUCKET_ADDRESS } from './cloudstorage.js';

export const changeBucket = (siteData) => {
  let bucketNew = '';
  if (window.origin === 'https://developer.huawei.com') {
    DEFAULT_BUCKET_ADDRESS.forEach(el => {
      if (el.value === siteData) {
        bucketNew = el.address;
      }
    });
  } else if (window.origin === 'https://developerlfdev.hwcloudtest.cn') {
      STTESTDEFAULT_BUCKET_ADDRESS.forEach((el) => {
        if (el.value === siteData) {
          bucketNew = el.address;
        }
      });
  } else {
    TESTDEFAULT_BUCKET_ADDRESS.forEach((el) => {
      if (el.value === siteData) {
        bucketNew = el.address;
      }
    });
  }

  return bucketNew;
};
