<template>
  <div v-if="manuscriptVisible">
    <el-dialog
      :title="$t('ctp.manuscriptManage')"
      :visible.sync="manuscriptVisible"
      width="50%"
      @close="close"
      close-on-click-modal="false"
    >
      <!-- 源稿下载 -->
      <div class="label1">
        <span class="label" :class="lan==='zh'?'zh_label':'ru_label'">{{ $t("ctp.downloadSourceFile") }}{{ $t("ctp.colon") }}</span>
        <el-button type="text" @click="downloadOrigin">
          {{ detailData.sourceFilePath ? detailData.sourceFilePath.split("/").pop() : "" }}
        </el-button>
      </div>
      <!-- 译稿和TM文件上传 -->
      <div>
        <div class="tips">
          <span class="label" :class="lan==='zh'?'zh_label':'ru_label'">{{ $t("ctp.uploadThetranslatedVersion" ) }}</span>
          <span>{{ $t("ctp.tips.uploadSize",{size: uploadFileSize} ) }}</span>
        </div>
        <div class="detail-dialog-div upload-translate-div" :class="lan==='zh'?'zh_wl':'ru_wl'">
          <el-upload
            action
            :auto-upload="false"
            :on-change="cosTranslated"
            multiple
            :on-remove="onRemoveFile"
            accept="text/xml, application/json, .zip, .csv, .xlsx"
            :limit="1"
            ref="uploadTranslateDiv"
            :file-list="replyFiles"
            :on-preview="handlePreview"
            :on-exceed="handleExceed"
          >
            <el-button>{{ $t("ctp.upload") }}</el-button>
          </el-upload>
        </div>
        <div class="tips">
          <span class="label" :class="lan==='zh'?'zh_label':'ru_label'">{{ $t("ctp.uploadingTMFiles" ) }}</span>
          <span>{{ $t("ctp.tips.uploadSize",{size: uploadFileSize} ) }}</span>
        </div>
        <div class="detail-dialog-div" :class="lan==='zh'?'zh_wl':'ru_wl'">
          <el-upload
            action
            :auto-upload="false"
            :on-change="cosReplyTM"
            multiple
            :on-remove="removeTmFile"
            accept=".tmx, .zip"
            ref="uploadTmx"
            :file-list="tmFiles"
            :limit="1"
            :on-preview="handlePreview"
            :on-exceed="handleExceed"
          >
            <el-button>{{ $t("ctp.upload") }}</el-button>
          </el-upload>
        </div>
      </div>
      <!-- 历史上传记录 -->
      <el-collapse>
        <el-collapse-item :title="$t('ctp.updateHistory')">
          <div class="historicalUploadRecords">
            {{ $t("ctp.historicalUploadRecords") }}
          </div>
          <el-table :data="detailData.taskReplyList" border max-height="155">
            <el-table-column :label="$t('ctp.uploadTime')" width="210">
              <template slot-scope="scope">
                <span v-if="scope.row.createTimeUTC">{{ Number(scope.row.createTimeLocal)|formatDate }}</span>
                <span v-else>{{ scope.row.createTime }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('ctp.translation')">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  class="content-hidden"
                  @click="downloadTranslated(scope.row)"
                >
                  {{ scope.row.fileName }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column :label="$t('ctp.TMFiles')">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  class="content-hidden"
                  @click="downloadTmx(scope.row)"
                >
                  {{ scope.row.tmName }}
                </el-link>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
      <span slot="footer">
        <el-button
          type="primary"
          @click="submitReply"
          :disabled="uploadTmx || uploadTranslate"
        >{{ $t("ctp.ok") }}</el-button>
        <el-button @click="close">{{ $t("ctp.cancel") }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { interactiveHaLog } from '../../util/interactiveHaLog.js';
import { timestampToDate, getSiteId } from '../../util/common.js';
import { changeBucket } from '../../util/changeBucket.js';
export default {
  name: 'ManuscriptManageDialog',
  filters: {
    formatDate(value) {
      if (!value) return '';
      return timestampToDate(value);
    }
  },
  props: {
    manuscriptVisible: {
      type: Boolean,
      default: false
    }, // 显示隐藏
    detailData: {
      type: Object,
      default: () => {}
    }, // 数据
    uploadFileSize: {
      type: Number,
      default: null
    },
    siteData: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      refreshStatus: false, // 是否刷新页面
      replyFiles: [], // 译稿文件数组
      tmFiles: [], // TM文件数组
      uploadTmx: false,
      uploadTranslate: false,
      historicalUploadRecords: false,
      zoneIdData: '',
      lan:
        localStorage.getItem('agc_lang') ||
        localStorage.getItem('agc_lang_site'), // 系统语言
    };
  },
  methods: {
    close() {
      this.manuscriptVisible = false;
      this.historicalUploadRecords = false;
      this.replyFiles = [];
      this.tmFiles = [];
      this.$emit('cmanuscriptVisible', false, this.refreshStatus);
    },
    // 下载源稿
    downloadOrigin() {
      this.refreshStatus = false;
      if (this.detailData.sourceFilePath) {
        interactiveHaLog({
          title: '翻译服务SP--下载',
          tagType: 'TranslationService',
          translationServiceId: this.detailData.taskId
        });
        const storage = this.$agc.storage();
        const { sourceFilePath } = this.detailData;
        const childPath =
          sourceFilePath.indexOf(':') > -1
            ? sourceFilePath.substring(sourceFilePath.indexOf(':') + 1)
            : sourceFilePath;
        const child = storage.storageReference().child(childPath);
        child.location.bucket = changeBucket(this.siteData);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
          AGC.callServiceAPI(
            'translation/v1/sp/downloadOrigin',
            {
              body: {
                spId: this.detailData.agc_spId,
                taskId: this.detailData.taskId
              }
            },
            'POST',
            getSiteId(this.siteData),
            3000,
            res => {
              if (res.refresh === true) {
                this.refreshStatus = true;
              }
            }
          );
        });
      } else {
        this.$alertMsg(this.$t('ctp.tips.noDownloadFile'), this.$t('ctp.tips'));
      }
    },
    // 选择译稿文件
    cosTranslated(file, fileList) {
      interactiveHaLog({
        title: '翻译服务SP--上传译稿',
        tagType: 'TranslationService',
        translationServiceId: this.detailData.taskId
      });
      this.uploadTranslate = true;
      const originalName = file.name;
      const suufix = originalName.split('.').pop().toLowerCase();
      if (!['csv', 'zip', 'xml', 'json', 'xlsx'].includes(suufix)) {
        this.$alertMsg(this.$t('ctp.tips.tranType'), this.$t('ctp.warning'));
        fileList.pop();
        return;
      }
      if (Math.ceil(file.size / 1024) > Number(this.uploadFileSize) * 1024) {
        this.$alertMsg(
          this.$t('ctp.tips.fileSizeOver', { size: this.uploadFileSize }),
          this.$t('ctp.warning')
        );
        fileList.pop();
        return;
      }
      this.$refs.uploadTranslateDiv.$children[1].$el.title = originalName;
      AGC.callServiceAPI(
        'translation/v1/storagePath',
        { body: { taskId: this.detailData.taskId } },
        'POST',
        getSiteId(this.siteData),
        3000,
        res => {
          if (res.ret.code === 0) {
            const storage = this.$agc.storage();
            const storageRef = storage.storageReference();
            storageRef.location.bucket = changeBucket(this.siteData);
            const cloudStoragePath = res.data + originalName;
            const metatData = { teamid: this.detailData.teamId };
            const uploadTask = storageRef
              .child(cloudStoragePath)
              .put(file.raw, {customMetadata: metatData});
            uploadTask.on(
              'state_changed',
              snapshot => {
                switch (snapshot.state) {
                case 'paused':
                  break;
                case 'running':
                  break;
                case 'success':
                  break;
                case 'canceled':
                  break;
                case 'error':
                  break;
                default:
                  break;
                }
              },
              () => {},
              () => {
                const addressNew = changeBucket(this.siteData);
                file.path = `${addressNew}:${cloudStoragePath}`;
                this.replyFiles = fileList;
                this.uploadTranslate = false;
              }
            );
          } else {
            this.$alertMsg(
              this.$t('ctp.tips.reUpload'),
              this.$t('ctp.warning')
            );
            this.uploadTranslate = false;
          }
        },
        err => {
          this.$alertMsg(this.$t('ctp.tips.reUpload'), this.$t('ctp.warning'));
          this.uploadTranslate = false;
          return err;
        }
      );
    },

    /** 选择TM文件 */
    cosReplyTM(file, fileList) {
      interactiveHaLog({
        title: '翻译服务SP--上传TM文件',
        tagType: 'TranslationService',
        translationServiceId: this.detailData.taskId
      });
      this.uploadTmx = true;
      const originalName = file.name;
      const suufix = originalName.split('.').pop().toLowerCase();
      if (suufix !== 'tmx' && suufix !== 'zip') {
        this.$alertMsg(this.$t('ctp.tips.tmxType'), this.$t('ctp.warning'));
        fileList.pop();
        return;
      }

      if (Math.ceil(file.size / 1024) > Number(this.uploadFileSize) * 1024) {
        this.$alertMsg(
          this.$t('ctp.tips.fileSizeOver', { size: this.uploadFileSize }),
          this.$t('ctp.warning')
        );
        fileList.pop();
        return;
      }
      this.$refs.uploadTmx.$children[1].$el.title = originalName;
      AGC.callServiceAPI(
        'translation/v1/storagePath',
        { body: { taskId: this.detailData.taskId } },
        'POST',
        getSiteId(this.siteData),
        3000,
        res => {
          if (res.ret.code === 0) {
            const storage = this.$agc.storage();
            const storageRef = storage.storageReference();
            storageRef.location.bucket = changeBucket(this.siteData);
            const storagePath = res.data + originalName;
            const metatData = { teamid: this.detailData.teamId };
            const uploadTask = storageRef
              .child(storagePath)
              .put(file.raw, {customMetadata: metatData});
            uploadTask.on(
              'state_changed',
              snapshot => {
                switch (snapshot.state) {
                case 'paused':
                  break;
                case 'running':
                  break;
                case 'success':
                  break;
                case 'canceled':
                  break;
                case 'error':
                  break;
                default:
                  break;
                }
              },
              () => {},
              () => {
                const addressNew = changeBucket(this.siteData);
                file.path = `${addressNew}:${storagePath}`;
                this.tmFiles = fileList;
                this.uploadTmx = false;
              }
            );
          } else {
            this.$alertMsg(
              this.$t('ctp.tips.reUpload'),
              this.$t('ctp.warning')
            );
            this.uploadTmx = false;
          }
        },
        err => {
          this.$alertMsg(this.$t('ctp.tips.reUpload'), this.$t('ctp.warning'));
          this.uploadTmx = false;
          return err;
        }
      );
    },
    // 删除文件时触发
    onRemoveFile(file, fileList) {
      this.uploadTranslate = false;
      if (file.path) {
        const storage = this.$agc.storage();
        const storageRef = storage.storageReference();
        storageRef.location.bucket = changeBucket(this.siteData);
        const cloudStoragePath = file.path.substring(file.path.indexOf(':') + 1);
        storageRef
          .child(cloudStoragePath)
          .delete()
          .then()
          .catch(() => {
            this.$alertMsg(
              this.$t('ctp.error.deleteFail'),
              this.$t('ctp.warning')
            );
          });
        this.replyFiles = fileList;
      }
    },
    // 点击文件列表已上传文件
    handlePreview(file) {
      if (file.path) {
        const storage = this.$agc.storage();
        const { path } = file;
        const childPath =
          path.indexOf(':') > -1 ? path.substring(path.indexOf(':') + 1) : path;
        const child = storage.storageReference().child(childPath);
        child.location.bucket = changeBucket(this.siteData);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
        });
      }
    },
    removeTmFile(tmFile, fileList) {
      this.uploadTmx = false;
      if (tmFile.path && tmFile.path.length > 0) {
        const storage = this.$agc.storage();
        const storageRef = storage.storageReference();
        storageRef.location.bucket = changeBucket(this.siteData);
        const cloudStorageTMPath = tmFile.path.substring(
          tmFile.path.indexOf(':') + 1
        );
        storageRef
          .child(cloudStorageTMPath)
          .delete()
          .then()
          .catch(() => {
            this.$alertMsg(
              this.$t('ctp.error.deleteFail'),
              this.$t('ctp.warning')
            );
          });
        this.tmFiles = fileList;
      }
    },
    handleExceed() {
      this.$alertMsg(this.$t('ctp.warn.maxFileNum'), this.$t('ctp.warning'));
    },
    // 供应商回稿上传译稿及TM文件
    submitReply() {
      if (this.replyFiles.length < 1 || this.tmFiles.length < 1) {
        this.$alertMsg(
          this.$t('ctp.warning.chooseTranTM'),
          this.$t('ctp.warning')
        );
        return;
      }
      const tbody = {};
      const replayArr = [];
      const tmArr = [];
      this.replyFiles.forEach(reply => {
        replayArr.push({ name: reply.name, path: reply.path });
      });
      this.tmFiles.forEach(tm => {
        tmArr.push({ name: tm.name, path: tm.path });
      });
      tbody.tmPath = tmArr;
      tbody.uid = this.detailData.agc_uid;
      tbody.spId = this.detailData.agc_spId;
      tbody.filePath = replayArr;
      tbody.taskId = this.detailData.taskId;
      const url = 'translation/v1/sp/uploadReply';
      AGC.callServiceAPI(
        url,
        { body: tbody },
        'POST',
        getSiteId(this.siteData),
        30000,
        res => {
          this.refreshStatus = true;
          this.close();
          if (res.ret.code === 0) {
            this.replyFiles = [];
            this.tmFiles = [];
            this.$promptMsg(
              this.$t('ctp.tips.uploadSuccess'),
              this.$t('ctp.tips')
            );
            this.$emit('cmanuscriptVisible', false, this.refreshStatus);
          } else if (res.ret.code === 141940561696) {
            this.$alertMsg(
              `${this.$t('ctp.tips.downOriginal')}(${
                res.errorCode ? res.errorCode : res.ret.code
              })`,
              this.$t('ctp.warning')
            );
          } else if (res.ret.code === 141940561698) {
            this.save00 = false;
            this.payingDialogVisible = false;
            this.savingDialogVisible = false;
            if (res.ret.msg === 'tmx check path invalid.') {
              this.$alertMsg(
                `${this.$t('ctp.tips.spTmx.typeError')}(${res.code ? res.code : res.ret.code})`, this.$t('ctp.warning')
              );
            } else {
              this.$alertMsg(
                `${this.$t('ctp.tips.spFile.typeError')}(${res.code ? res.code : res.ret.code})`, this.$t('ctp.warning')
              );
            }
          } else {
            this.save00 = false;
            this.payingDialogVisible = false;
            this.savingDialogVisible = false;
            this.$alertMsg(
              `${this.$t('ctp.tips.payError.abnormal')}(${
                res.errorCode ? res.errorCode : res.ret.code
              })`,
              this.$t('ctp.warning')
            );
          }
        },
        err => {
          this.close();
          this.$emit('cmanuscriptVisible', false, this.refreshStatus);
          this.$alertMsg(
            this.$t('ctp.error.systemAbnormal'),
            this.$t('ctp.warning')
          );
          return err;
        }
      );
    },
    downloadTranslated(data) {
      if (this.detailData.taskReplyList && data) {
        const taskReply0 = data;
        const replyPath = taskReply0.filePath;
        const childPath =
          replyPath.indexOf(':') > -1
            ? replyPath.substring(replyPath.indexOf(':') + 1)
            : replyPath;
        const storage = this.$agc.storage();
        const child = storage.storageReference().child(childPath);
        child.location.bucket = changeBucket(this.siteData);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
        });
      } else {
        this.$alertMsg(this.$t('ctp.tips.noTranslated'), this.$t('ctp.tips'));
      }
    },
    downloadTmx(data) {
      if (this.detailData.taskReplyList && data) {
        const taskReply0 = data;
        const replyPath = taskReply0.tmPath;
        const childPath =
          replyPath.indexOf(':') > -1
            ? replyPath.substring(replyPath.indexOf(':') + 1)
            : replyPath;
        const storage = this.$agc.storage();
        const child = storage.storageReference().child(childPath);
        child.location.bucket = changeBucket(this.siteData);
        child.getDownloadURL().then(downloadURL => {
          window.open(downloadURL, '_self');
        });
      } else {
        this.$alertMsg(this.$t('ctp.tips.noTranslated'), this.$t('ctp.tips'));
      }
    }
  }
};
</script>

<style scoped>
div /deep/ .el-collapse-item__header {
  background: #f7f7f7;
  padding: 0 20px;
  border: none;
  border-radius: 4px;
  margin-bottom: 20px;
}
.detail-dialog-div {
  margin-top: 4px;
  margin-bottom: 20px;
}
.ru_wl{
  margin-left: 153px;
}
.zh_wl{
  margin-left: 110px;
}
.label1 {
  margin-bottom: 10px;
}
.ru_label{
  width: 145px;
}
.zh_label{
  width: 100px;
}
.label {
  word-break: break-word;
  display: inline-block;
  text-align: right;
  margin-right: 8px;
}
.historicalUploadRecords {
  padding-left: 20px;
  margin-bottom: 16px;
}
div /deep/.el-table--striped td,
div /deep/.el-table--striped th.is-leaf {
  border-bottom-color: #ccc !important;
}
div /deep/.el-table .cell {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
div /deep/ .content-hidden {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
