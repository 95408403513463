<template>
  <agc-dialog
    :title="$t('ctp.authorizationDescription')"
    :visible.sync="authorisationDialog"
    @close="handleCloseAuth"
    width="30%"
  >
    <p class="recharge-tips">
      <i18n path="ctp.authorizeInfo" tag="a">
        <a
          :href="authorisationUrl"
          class="recharge-link"
          type="primary"
          target="_blank"
        >{{ $t("ctp.authorizeLink") }}</a>
      </i18n>
    </p>
    <p class="recharge-tips">
      <el-checkbox v-model="checked" @change="handleChecked" />
      <span class="authorize-selection-text">{{ $t("ctp.authorizeSelection") }}</span>
    </p>
    <span slot="footer">
      <el-button type="primary" @click="handleSetPrivacy" :disabled="!checked">
        {{ $t('ctp.agreeAuthorize') }}
      </el-button>
      <el-button type="primary" @click="handleCloseAuth">{{ $t('ctp.denyAuthorization') }}</el-button>
    </span>
  </agc-dialog>
</template>

<script>
import { setPrivacy } from '../../api/sp.js';
import Cookie from 'js-cookie';
export default {
  name: 'AuthorisationDialog',
  props: {
    siteval: {
      type: String,
      default: () => []
    }
  },
  data() {
    return {
      checked: false,
      authorisationUrl: 'https://terms-dre.platform.hicloud.com/agreementservice/developer' +
        '/getAgreementTemplate?agrType=1165&country=cn&language=zh_cn&version=2021010804',
      authorisationDialog: false,
      agcUid: '',
      agcSpId: '',
      zoneIdData: ''
    };
  },
  mounted() {
    this.zoneIdData = sessionStorage.getItem('tszoneId');
  },
  methods: {
    handleSetPrivacy() {
      this.agcUid =
        Cookie.get('agc_uid') || sessionStorage.getItem('agc_uid');
      this.agcSpId = sessionStorage.getItem('agc_translation_spid');
      const body = {
        userId: this.agcUid,
        spId: this.agcSpId,
        siteId: this.siteval
      };
      setPrivacy(body, this.zoneIdData).then(res => {
        if (res.ret.code === 0) {
          this.authorisationDialog = false;
          this.$emit('authSuccess', true);
        } else {
          this.authorisationDialog = false;
          this.$emit('authSuccess', false);
          this.$alertMsg(
            this.$t('ctp.error.systemBusy'),
            this.$t('ctp.warning')
          );
        }
      });
    },
    handleCloseAuth() {
      this.authorisationDialog = false;
      this.checked = false;
      this.$emit('authSuccess', false);
    },
    handleOpenAuthorisation() {
      this.checked = false;
      this.authorisationDialog = true;
    }
  }
};
</script>

<style scoped>
.recharge-tips{
  margin-bottom: 12px;
  word-break: break-word;
}
.recharge-link {
  color: #007dff;
  text-decoration: none;
}
.recharge-link:link {
  color: #007dff;
  text-decoration: none;
}
.recharge-link:visited {
  color: #007dff;
  text-decoration: none;
}
.recharge-link:hover {
  color: #3397ff;
  text-decoration: none;
}
.recharge-link:active {
  color: #3397ff;
  text-decoration: none;
}
.authorize-selection-text {
  margin-left: 5px;
}
</style>
